import { Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import NewsletterSignup from "@/components/mresvia/NewsletterSignup";
import { BannerNewsletterSignupProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const BannerNewsletterSignup: React.FC<BannerNewsletterSignupProps> = ({
  // SPKVX-1816 todo: remove sizes as props and hard code on line 21 again
  gridSize = [4, 8, 6],
  gridPush = [0, 0, 3],
  text,
  newsletter
}) => {
  return (
    <Grid>
      <GridColumn sx={{ alignItems: "center", textAlign: "center" }}>
        <BlockText text={text} />
      </GridColumn>
      <GridColumn size={gridSize} push={gridPush} sx={{ mt: 4 }}>
        <NewsletterSignup isCorpTheme={true} {...newsletter} />
      </GridColumn>
    </Grid>
  );
};
