import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockColumnsProps } from "@/types/Block";

import { BlockText } from "./BlockText";

export const BlockColumns: React.FC<BlockColumnsProps> = ({
  backgroundColor,
  borderRadius,
  columns,
  wrap
}) => {
  const isScrollable = wrap === false;
  const styles = React.useMemo<React.ComponentProps<typeof Box>["sx"]>(
    () =>
      isScrollable
        ? {
            overflowY: "auto",
            scrollbarWidth: "none",
            width: "100%",
            // @ts-ignore
            "::-webkit-scrollbar": {
              display: "none"
            }
          }
        : {},
    [isScrollable]
  );

  return (
    <Box sx={styles} tabIndex={isScrollable ? 0 : undefined}>
      <Grid sx={{ backgroundColor: backgroundColor, borderRadius: borderRadius }} wrap={wrap}>
        {columns.map((column, i) => (
          <GridColumn
            key={i}
            fillEnd={column.fillEnd}
            fillGapEnd={column.fillGapEnd}
            fillGapStart={column.fillGapStart}
            fillStart={column.fillStart}
            push={column.push}
            size={column.size}
            sx={{
              alignItems: column.alignX,
              backgroundColor: column.backgroundColor,
              backgroundImage: column.backgroundImage,
              backgroundPosition: column.backgroundPosition,
              backgroundRepeat: column.backgroundRepeat,
              backgroundSize: column.backgroundSize,
              borderBottomLeftRadius: column.borderBottomLeftRadius,
              borderBottomRightRadius: column.borderBottomRightRadius,
              borderRadius: column.borderRadius,
              borderTopLeftRadius: column.borderTopLeftRadius,
              borderTopRightRadius: column.borderTopRightRadius,
              boxShadow: column.boxShadow,
              gap: column.gap,
              justifyContent: column.alignY,
              mb: column.mb,
              mt: column.mt,
              outline: column.outline,
              outlineColor: column.outlineColor
            }}
          >
            <BlockText as="fragment" text={column.text} />
          </GridColumn>
        ))}
      </Grid>
    </Box>
  );
};
