import {
  Box,
  ContentItem,
  ContentStrip,
  Grid,
  GridColumn,
  Headline,
  Text
} from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithContentStripProps } from "@/types/Block";

export const TextWithContentStrip: React.FC<TextWithContentStripProps> = (props) => {
  if (!props) {
    return null;
  }

  const { contentItems, title } = props;

  return (
    <Box sx={{ py: [5, 5, 10] }}>
      <Grid>
        <GridColumn>
          <Headline as="h2" level={4} variant="bold">
            {title}
          </Headline>
        </GridColumn>
      </Grid>
      <ContentStrip>
        {contentItems?.map((item, i) => (
          <ContentItem
            key={i}
            image={item.image}
            imageFocalPointX={item.imageFocalPointX}
            imageFocalPointY={item.imageFocalPointY}
            title={item.title}
          >
            <Text sx={{ mt: 2, whiteSpace: "pre-line" }}>{item.text?.join("\n")}</Text>
          </ContentItem>
        ))}
      </ContentStrip>
    </Box>
  );
};
