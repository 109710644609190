import {
  Box,
  Checkbox,
  CheckboxProps,
  DropdownOption,
  Grid,
  GridColumn,
  SearchBar,
  Text
} from "@modernatx/ui-kit-react";
import React from "react";

import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../components/BlockText";
import { useAutocompleteSearch } from "./hooks/useAutocompleteSearch";
import { useUrlState } from "./hooks/useUrlState";

export type FinderCheckboxProps = Omit<CheckboxProps, "name"> & {
  name: "rsv" | "covid" | "covid-children";
  searchForProducts: (
    | "unbranded"
    | "2324-covid"
    | "2425-covid-children"
    | "2425-covid"
    | "2425-rsv"
  )[];
};

export interface FinderHeaderBlockProps {
  text: BlockTextProps[];
  selectOptions?: FinderCheckboxProps[];
  searchText?: string;
  image?: string;
  imageAspectRatio?: number;
}
export interface FinderHeaderProps extends FinderHeaderBlockProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  selectedProducts: string[];
  setSelectedSearchValue: (option: DropdownOption) => void;
}

export const FinderHeader: React.FC<FinderHeaderProps> = ({
  image,
  imageAspectRatio,
  searchText,
  selectedProducts,
  searchValue,
  setSearchValue,
  setSelectedSearchValue,
  selectOptions,
  text
}) => {
  const [showNoResults, setShowNoResults] = React.useState<boolean>(false);
  const [pendingSubmit, setPendingSubmit] = React.useState<boolean>(false);

  const { setSelectedProducts } = useUrlState();

  const { isFetchingSuggestions, searchSuggestions } = useAutocompleteSearch(searchValue);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleCheckboxChange = (name: string) => {
    if (selectedProducts.includes(name)) {
      setSelectedProducts(selectedProducts.filter((product) => product !== name));
    } else {
      setSelectedProducts([...selectedProducts, name]);
    }
  };

  const searchSuggestionsList = searchSuggestions.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id
  }));

  const handleSearchSuggestionClick = React.useCallback(
    (option: DropdownOption) => {
      setSelectedSearchValue(option);
    },
    [setSelectedSearchValue]
  );

  const handleSearchSubmit = React.useCallback(() => {
    if (isFetchingSuggestions) {
      // If autocomplete search is pending, wait to submit until that fetch is complete.
      setPendingSubmit(true);
    } else if (searchSuggestionsList.length > 0) {
      const defaultOption = searchSuggestionsList[0];
      handleSearchSuggestionClick(defaultOption!);
    }
  }, [isFetchingSuggestions, searchSuggestionsList, handleSearchSuggestionClick]);

  // Handle pending submit once suggestions are fetched. This supports the case
  // where a user submits their search before the suggestions are finished fetching.
  React.useEffect(() => {
    if (pendingSubmit && !isFetchingSuggestions) {
      handleSearchSubmit();
      setPendingSubmit(false);
    }
  }, [pendingSubmit, isFetchingSuggestions, handleSearchSubmit]);

  // Handle "No Results" when search suggestions are empty.
  React.useEffect(() => {
    setShowNoResults(
      !isFetchingSuggestions && searchSuggestionsList.length === 0 && searchValue.length > 1
    );
  }, [searchSuggestionsList, searchValue, isFetchingSuggestions]);

  return (
    <Box>
      <Grid>
        <GridColumn size={[4, 8, 6]} sx={{ py: [10, 10, 15] }}>
          <BlockText text={text} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: [2, 2, 5],
              pb: 5,
              pt: 2
            }}
          >
            {selectOptions?.map((select, i) =>
              selectOptions.length === 1 ? (
                <Text key={i}>{select.label}</Text>
              ) : (
                <Checkbox
                  key={i}
                  name={select.name}
                  label={select.label}
                  value={selectedProducts.includes(select.name)}
                  onChange={() => handleCheckboxChange(select.name)}
                />
              )
            )}
          </Box>
          <SearchBar
            label={searchText || "Search by city, state, or zip"}
            value={searchValue}
            dropdownOptions={searchSuggestionsList}
            type="select"
            showNoResults={showNoResults}
            onChange={handleSearchChange}
            onClickSearch={handleSearchSubmit}
            onSelectOption={handleSearchSuggestionClick}
          />
        </GridColumn>
        {!!imageAspectRatio && (
          <GridColumn
            size={[4, 8, 6]}
            sx={{
              display: ["none", "none", "flex"],
              position: "relative"
            }}
          >
            {image && <Box as="img" src={image} sx={{ height: "100%", position: "absolute" }} />}
          </GridColumn>
        )}
      </Grid>
    </Box>
  );
};
