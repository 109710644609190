import {
  Box,
  Grid,
  GridColumn,
  RadioButton,
  RadioGroup,
  Text,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import useRadioGroup from "@/hooks/useRadioGroup";
import { BarChartProps, RespiratoryDiseaseGraphProps } from "@/types/Block";

import { BarChart } from "../blocks/BarChart";
import { BlockText } from "../BlockText";
import useBarChartData from "./hooks/useBarChartData";
import { StateSelect } from "./StateSelect";

interface RespiratoryDiseaseData {
  covid: number;
  flu: number;
  rsv: number;
}

const barChart: BarChartProps = {
  barChartGroups: [{ barChartSections: [{ bars: [{ stacks: [0] }] }] }],
  barTitleSize: "md",
  chartColors: ["accent02", "accent03", "accent01"],
  collapseOnMobile: true,
  groupBackgroundColors: [],
  intervals: 5,
  legendLabels: [],
  labelSelect: "Percentage of Emergency Room visits for viral respiratory illness in ",
  labelYAxis: "",
  sectionTitles: [],
  yMax: 6
};

const calculateYMax = (min: number, data: RespiratoryDiseaseData) => {
  if (data.covid < min && data.flu < min && data.rsv < min) {
    return min;
  }
  return Math.min(100, Math.max(data.covid, data.flu, data.rsv) + 2);
};

export const RespiratoryDiseaseGraph: React.FC<RespiratoryDiseaseGraphProps> = ({
  radioGroup,
  title,
  text
}) => {
  const [selectedState, setSelectedState] = React.useState<string | null>(null);
  const { selectedValue, handleChange: handleRadioChange } = useRadioGroup(
    radioGroup[0]?.value || "ed-visits"
  );
  const barData = useBarChartData(selectedValue, selectedState || "");

  return (
    <Grid>
      <GridColumn key="RespiratoryDiseaseStatus" size={[4, 8, 6]}>
        <BlockText weight={"bold"} color={"accent02"} size={"5xl"} text={title} spacingBottom={2} />
        <BlockText text={text} spacingBottom={5} />
        <RadioGroup
          label={""}
          value={selectedValue}
          onChange={(value) => {
            handleRadioChange(value);
            setSelectedState(null);
          }}
        >
          {radioGroup.map(({ label, value }, index) => (
            <RadioButton key={index} label={label || ""} value={value} sx={{ p: 0 }} />
          ))}
        </RadioGroup>
        {selectedValue === "ed-visits" && (
          <Box sx={{ py: 5 }}>
            <StateSelect label="Search by State" search={(state) => setSelectedState(state)} />
          </Box>
        )}
      </GridColumn>
      <GridColumn key="RespiratoryDiseaseGraph" size={[4, 8, 6]}>
        <Text
          as="h4"
          variant="bold"
          sx={{
            justifyContent: "center",
            p: 10,
            pt: 0,
            ml: [0, 0, 15],
            textAlign: "center"
          }}
        >
          {barData && Math.max(barData.covid, barData.flu, barData.rsv) === 0
            ? "No data available"
            : radioGroup.find((r) => r.value === selectedValue)?.label +
              " in " +
              (selectedState || "the U.S.")}
        </Text>
        <ThemeOverride theme={{ colorsLight: { accent03: "#3E2235" } }} mode="light">
          <BarChart
            {...barChart}
            labelSelect=""
            yMax={calculateYMax(6, barData || { covid: 0, flu: 0, rsv: 0 })}
            barChartGroups={[
              {
                barChartSections: [
                  {
                    bars: [
                      {
                        barTitle: "COVID",
                        barLabels: [
                          {
                            text: `${barData?.covid}%`,
                            size: ["lg", "2xl", "2xl"],
                            color: "accent02",
                            overflowWrap: "normal"
                          }
                        ],
                        stacks: [barData?.covid || 0, 0, 0]
                      },
                      {
                        barTitle: "Flu",
                        barLabels: [
                          {
                            text: `${barData?.flu}%`,
                            size: ["lg", "2xl", "2xl"],
                            color: "accent03",
                            overflowWrap: "normal"
                          }
                        ],
                        stacks: [0, barData?.flu || 0, 0]
                      },
                      {
                        barTitle: "RSV",
                        barLabels: [
                          {
                            text: `${barData?.rsv}%`,
                            size: ["lg", "2xl", "2xl"],
                            color: "accent01",
                            overflowWrap: "normal"
                          }
                        ],
                        stacks: [0, 0, barData?.rsv || 0]
                      }
                    ]
                  }
                ]
              }
            ]}
          />
        </ThemeOverride>
      </GridColumn>
    </Grid>
  );
};
