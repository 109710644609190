import React from "react";

import { FinderLocation } from "@/types/FinderLocation";

import { useMapsLibrary } from "./googleMaps/GoogleAPIProvider";
import { GoogleMapsContext } from "./googleMaps/Map";
import { useIsMobile } from "./hooks/useIsMobile";
import { useUrlState } from "./hooks/useUrlState";

interface Props {
  results: FinderLocation[] | null;
  selectedLocation?: FinderLocation | null;
  zoom?: number;
}
// Handles the logic for the map view, including setting the bounds and zoom level, and panning to a selected location
// This component is NOT a wrapper around the Google Maps API, but rather a custom controller built specifically for Finder
const MapViewController = ({ results, selectedLocation }: Props) => {
  const { map, defaultZoom } = React.useContext(GoogleMapsContext);
  const singleLocationZoomLevel = 14;
  const [lastZoomLevel, setLastZoomLevel] = React.useState<number>(defaultZoom);
  if (!map) {
    throw new Error("MapViewController must be used within a Map component");
  }
  const mapLibrary = useMapsLibrary();
  const isMobileView = useIsMobile();
  const { placeId } = useUrlState();

  const applyOffset = React.useCallback(() => {
    if (!isMobileView && map) {
      const panOffsetX = map.getDiv().offsetWidth * 0.17;
      map.panBy(-panOffsetX, 0);
    }
  }, [isMobileView, map]);

  // Set the bounds of the map to fit all search results
  React.useEffect(() => {
    if (!mapLibrary) {
      return;
    }
    if (!!results?.length) {
      const bounds = new google.maps.LatLngBounds();
      results.forEach((result) => {
        bounds.extend({
          lat: result.location.position.lat,
          lng: result.location.position.lon
        });
      });
      map.fitBounds(bounds);
      if (results.length === 1) {
        map.setZoom(singleLocationZoomLevel);
      }
      setLastZoomLevel(map.getZoom() || defaultZoom);
      applyOffset();
    } else if (placeId) {
      // If there are no search results, fetch the location details for the selected placeId
      // and set the map to that location
      fetch(`/api/location-details?placeId=${placeId}`)
        .then((response) => response.json())
        .then((data) => {
          map.setCenter({
            lat: data.geometry.location.lat,
            lng: data.geometry.location.lng
          });
          map.setZoom(singleLocationZoomLevel - 1);
          setLastZoomLevel(singleLocationZoomLevel - 1);
        })
        .catch(() => {});
    }
  }, [mapLibrary, results, map, applyOffset, defaultZoom, placeId]);

  // Define behavior for when a location is selected
  React.useEffect(() => {
    if (!!map && !!selectedLocation) {
      map.panTo({
        lat: selectedLocation.location.position.lat,
        lng: selectedLocation.location.position.lon
      });
      map.setZoom(lastZoomLevel);
      applyOffset();
    }
  }, [map, selectedLocation, applyOffset, lastZoomLevel, defaultZoom, placeId]);

  return null;
};

export default React.memo(MapViewController);
