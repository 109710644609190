import React from "react";

import { Country } from "@/types/Country";
import { countryCodeMap } from "@/utils/country";

export const useCountrySearch = (countries: Set<Country>) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredCountries = React.useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return Array.from(countries).filter((country) =>
      countryCodeMap[country]?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [countries, searchTerm]);

  const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearchTerm("");
  }, []);

  return { searchTerm, filteredCountries, handleSearchChange, clearSearch };
};
