import { Box, Grid, GridColumn, Link, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { FooterBlockProps } from "@/types/Block";

export const Footer: React.FC<FooterBlockProps> = ({ documentId, linkItems }) => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <Box sx={{ py: [5, 10, 10] }}>
      <Grid>
        <GridColumn>
          <Text as="p" size="xl" sx={{ textAlign: "center", color: "text02" }}>
            {linkItems?.map((link, i) => (
              <React.Fragment key={i}>
                <Link
                  href={link.href}
                  sx={{ fontSize: 4 }}
                  target={link.external ? "_blank" : undefined}
                >
                  {link.title}
                </Link>{" "}
                |{" "}
              </React.Fragment>
            ))}
            © {year} {documentId}
          </Text>
        </GridColumn>
      </Grid>
    </Box>
  );
};
