import { Box, BoxSx, FlagIcon, Target } from "@modernatx/ui-kit-react";
import React from "react";

import { SplitButtonBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export interface ButtonClickHandlers {
  onStartButtonClick?: () => void;
  onEndButtonClick?: () => void;
}

export const SplitButton: React.FC<SplitButtonBlockProps & ButtonClickHandlers> = ({
  startIcon,
  startText,
  endText,
  onStartButtonClick,
  onEndButtonClick
}) => {
  const targetStyles: BoxSx = {
    display: "flex",
    alignItems: "center",
    textDecoration: "underline",
    textDecorationColor: "transparent",
    borderColor: "transparent",
    borderRadius: "buttonBorderRadius",
    borderWidth: 1,
    borderStyle: "solid",
    transition: "colorMedium",
    ":hover": {
      textDecorationColor: "inherit"
    },
    ":active": {
      textDecorationColor: "inherit"
    },
    ":focus-visible": {
      borderColor: "buttonFocusBorderColorTertiary"
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <Target sx={{ ...targetStyles }} onClick={onStartButtonClick}>
          {startIcon && <FlagIcon icon={startIcon} size={"lg"} sx={{ paddingInlineEnd: 2 }} />}
          <BlockText text={startText} />
        </Target>
      </Box>
      {endText && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ paddingInlineStart: 2, paddingInlineEnd: 2 }}>
            <Box
              sx={{
                borderColor: "text01",
                borderStyle: "solid",
                height: "iconMd",
                borderWidth: "0 0 0 1px"
              }}
            />
          </Box>

          <Target sx={{ ...targetStyles }} onClick={onEndButtonClick}>
            <BlockText text={endText} />
          </Target>
        </Box>
      )}
    </Box>
  );
};
