import { Box, Grid, GridColumn, Icon, Target, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockText } from "@/components/BlockText";
import { useIsMobile } from "@/finder/hooks/useIsMobile";
import useBodyScrollLock from "@/hooks/useBodyScrollLock";
import { useClickOutside } from "@/hooks/useClickOutside";
import { ISIPanelProps } from "@/types/Block";

export type Expanded = "preview" | boolean;

export const ISIPanel: React.FC<ISIPanelProps> = ({
  textColumn1,
  textColumn2,
  title,
  titleMobile
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const panelContentRef = React.useRef<HTMLDivElement>(null);
  const windowHeightRef = React.useRef<number | null>(null);
  const toggleRef = React.useRef<HTMLElement>(null);
  const toggleHeightRef = React.useRef<number | null>(null);
  const [expanded, setExpanded] = React.useState<Expanded>(false);
  const [hidden, setHidden] = React.useState(true);
  const [transitioning, setTransitioning] = React.useState(false);
  const icon: React.ComponentProps<typeof Icon>["icon"] = !!expanded ? "caret-down" : "caret-up";
  const isMobile = useIsMobile();

  const transform = React.useMemo(() => {
    const toggleHeight = toggleHeightRef.current || 0;
    const peekHeight = isMobile ? 300 : 180;

    if (expanded === "preview" && toggleHeight) {
      // Just a peek so that 3 lines of text are showing
      return `translateY(calc(100% - ${toggleHeight + peekHeight}px))`;
    } else if (expanded === true) {
      return "translateY(0)";
    } else if (hidden) {
      return "translateY(calc(100% + 90px))";
    } else if (expanded === false) {
      return `translateY(calc(100% - ${toggleHeight}px))`;
    }
  }, [expanded, hidden, isMobile]);

  const handleExpandedChange = React.useCallback((expandedNext: Expanded) => {
    // We need to flag that the transition is starting
    // so we don't prematurely hide the content
    setTransitioning(true);
    setExpanded(expandedNext);
  }, []);

  useClickOutside(
    panelContentRef,
    React.useCallback(() => {
      if (expanded) {
        handleExpandedChange(false);
      }
    }, [expanded, handleExpandedChange])
  );

  const handleToggleClick = React.useCallback(() => {
    if (expanded === "preview") {
      handleExpandedChange(false);
    } else {
      handleExpandedChange(!expanded);
    }
  }, [expanded, handleExpandedChange]);

  const handleTransitionEnd = React.useCallback((e: React.TransitionEvent) => {
    if (e.target === e.currentTarget) {
      setTransitioning(false);
    }
  }, []);

  // Cache the window height to reduce DOM lookups and relayouts
  React.useEffect(() => {
    const handleResize = () => {
      windowHeightRef.current = window.innerHeight;
      toggleHeightRef.current = toggleRef.current?.offsetHeight || null;
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowHeightRef]);

  useBodyScrollLock(expanded, panelContentRef);

  React.useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const isContentInView =
          window.scrollY + (windowHeightRef.current || 0) >= contentRef.current.offsetTop;

        if ((isContentInView && !hidden) || (!isContentInView && hidden)) {
          setHidden(isContentInView);
        }
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [hidden, windowHeightRef]);

  React.useEffect(() => {
    handleExpandedChange("preview");
  }, [handleExpandedChange]);

  // If we are previewing, we should collapse the panel
  // for any interaction. Clicks will be taken care of using the
  // useClickOutside hook above.
  React.useEffect(() => {
    const collapseOnAnyInteraction = () => {
      handleExpandedChange(false);
    };

    if (expanded === "preview") {
      window.addEventListener("scroll", collapseOnAnyInteraction);
    }

    return () => {
      window.removeEventListener("scroll", collapseOnAnyInteraction);
    };
  }, [expanded, handleExpandedChange]);

  return (
    <>
      <Box
        ref={panelContentRef}
        sx={{
          background: "backgroundNeutral02",
          bottom: 0,
          maxHeight: "100%",
          position: "fixed",
          transform: transform,
          transition: "easeOutMedium",
          width: "100%",
          zIndex: "navbar"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
          onTransitionEnd={handleTransitionEnd}
        >
          <Target
            data-testid="isi-panel-toggle"
            sx={{ display: "block", width: "100%", flexGrow: 0 }}
            onClick={handleToggleClick}
          >
            <ThemeOverride mode="dark">
              <Box ref={toggleRef} sx={{ backgroundColor: "background01", py: [2, 4, 4] }}>
                <Grid>
                  <GridColumn
                    sx={{
                      alignItems: "center",
                      display: ["flex", "none", "none"],
                      flexDirection: "row"
                    }}
                  >
                    <Text variant="bold">{titleMobile}</Text>
                    <Icon
                      size="sm"
                      icon={icon}
                      sx={{ color: "text01", marginInlineStart: "auto" }}
                    />
                  </GridColumn>
                </Grid>
                <Box sx={{ display: ["none", "flex", "flex"] }}>
                  <Grid>
                    <GridColumn sx={{ alignItems: "center", flexDirection: "row" }}>
                      <Text size="xl" variant="bold">
                        {title}
                      </Text>
                      <Icon
                        size="sm"
                        icon={icon}
                        sx={{ color: "text01", marginInlineStart: "auto" }}
                      />
                    </GridColumn>
                  </Grid>
                </Box>
              </Box>
            </ThemeOverride>
          </Target>
          <Box
            sx={{
              flex: 1,
              // We have to manually create the height for Safari. The flex definition
              // covers it for every other browser.
              maxHeight: () =>
                toggleRef.current && expanded !== "preview"
                  ? `${(windowHeightRef.current || 0) - toggleRef.current.offsetHeight}px`
                  : null,
              overflow: "auto",
              visibility: !expanded && !transitioning ? "hidden" : null
            }}
            aria-hidden={!expanded}
          >
            <ThemeOverride mode="light">
              <Box sx={{ pb: [2, 10, 10], pt: [2, 5, 5] }}>
                <Grid>
                  <GridColumn size={[4, 3, 4]}>
                    <BlockText {...textColumn1} />
                  </GridColumn>
                  <GridColumn size={[4, 5, 8]}>
                    <BlockText {...textColumn2} />
                  </GridColumn>
                </Grid>
              </Box>
            </ThemeOverride>
          </Box>
        </Box>
      </Box>
      <Box ref={contentRef}>
        <Grid>
          <GridColumn>
            <Text size="3xl" variant="bold" sx={{ pb: 5 }}>
              {title}
            </Text>
          </GridColumn>
          <GridColumn>
            <Box
              sx={{
                columnCount: [1, 1, 2],
                gap: (theme) => `${theme.sizes.gridDesktopColumnGutter}px`,
                orphans: 3,
                widows: 3
              }}
            >
              <BlockText {...textColumn1} />
              <BlockText {...textColumn2} />
            </Box>
          </GridColumn>
        </Grid>
      </Box>
    </>
  );
};
