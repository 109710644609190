import { Box, Button, DropdownOption, Input, ThemeOverride } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useAutocompleteSearch } from "@/finder/hooks/useAutocompleteSearch";
import { VaccineFinderBlockProps } from "@/types/Block";

export const VaccineFinder: React.FC<VaccineFinderBlockProps> = ({
  button,
  input,
  layout = "horizontal",
  mode = "light",
  product = ""
}) => {
  const [searchValue, setSearchValue] = React.useState(input.value);
  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string | null>(null);

  const router = useRouter();
  const { searchSuggestions } = useAutocompleteSearch(searchValue);

  const searchSuggestionsList = searchSuggestions.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id
  }));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSuggestionClick = (option: DropdownOption) => {
    setSearchValue(option.label);
    setSelectedPlaceId(String(option.value));
  };

  const handleSearchSubmit = React.useCallback(() => {
    if (selectedPlaceId) {
      const queryParams = new URLSearchParams({ placeId: selectedPlaceId });
      if (product) {
        queryParams.append("products", product);
      }
      router.push(`/finder?${queryParams.toString()}`);
    }
  }, [selectedPlaceId, router, product]);

  return (
    <>
      <ThemeOverride mode={mode}>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", layout === "vertical" ? "column" : "row"],
            gap: 2,
            position: "relative"
          }}
        >
          <Input
            dropdownOptions={searchSuggestionsList}
            label={input.label}
            value={searchValue}
            type="select"
            sx={{ flex: 1 }}
            onChange={handleSearchChange}
            onSelectOption={handleSearchSuggestionClick}
          />
          <Button variant={button.variant} onClick={handleSearchSubmit}>
            {button.title}
          </Button>
        </Box>
      </ThemeOverride>
    </>
  );
};
