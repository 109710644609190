import { Box, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { NavLink } from "@/components/navigation/NavLink";
import { FinderBlockProps } from "@/types/Block";
import { FinderLocation } from "@/types/FinderLocation";

import { FinderHeader } from "./FinderHeader";
import { FinderList } from "./FinderList";
import { FinderListViewWrapper } from "./FinderListViewWrapper";
import { FinderMap } from "./FinderMap";
import { useFetchProductLocations } from "./hooks/useFetchProductLocations";
import { useIsMobile } from "./hooks/useIsMobile";
import { useUrlState } from "./hooks/useUrlState";

export const Finder: React.FC<FinderBlockProps> = ({
  distanceTemplate,
  header,
  map,
  searchType
}) => {
  const { selectedProducts, placeId } = useUrlState();
  const [searchResults, setSearchResults] = React.useState<FinderLocation[]>([]);
  const [selectedView, setSelectedView] = React.useState<"map" | "list">("map");
  const [selectedLocation, setSelectedLocation] = React.useState<FinderLocation | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const isMobileView = useIsMobile();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const { setSelectedSearchValue, isSearching } = useFetchProductLocations({
    selectOptions: header.selectOptions,
    setSearchResults,
    setSearchTerm,
    setSearchValue,
    searchType
  });
  const isMobileMapView = isMobileView && selectedView === "map";

  const productOptions = header.selectOptions || [];

  const finderListProps = {
    distanceTemplate,
    isSearching,
    productOptions,
    selectedProducts,
    searchTerm,
    selectedLocation,
    setSelectedLocation
  };

  const handleNavClick = (view: "map" | "list") => {
    setSelectedView(view);
  };

  React.useEffect(() => {
    if (!isMobileView) {
      setSelectedView("map");
    }
  }, [isMobileView]);

  React.useEffect(() => {
    setSelectedLocation(null);
  }, [searchTerm]);

  const locationsWithProducts = React.useMemo(() => {
    if (selectedProducts.length === 0) {
      return searchResults;
    }

    return searchResults.filter((location) =>
      selectedProducts.some((product) => location.products.includes(product))
    );
  }, [searchResults, selectedProducts]);

  // try to show first two parts of address (eg. Seattle, WA 98104),
  // then fallback to first element (Seattle), then fallback to whatever is passed in
  const sanitizedSearchTerm =
    searchTerm.split(",").slice(0, 2).join(", ") || searchTerm.split(",")[0] || searchTerm;

  return (
    <>
      {header && (
        <FinderHeader
          {...header}
          selectedProducts={selectedProducts}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSelectedSearchValue={setSelectedSearchValue}
        />
      )}
      {map && (
        // mobile only toggle menu
        <ThemeOverride mode="light">
          <Box
            sx={{
              background: "background01",
              display: ["flex", "flex", "none"],
              justifyContent: "space-around",
              height: "100%"
            }}
          >
            <NavLink
              index={0}
              active={selectedView === "list"}
              onClick={() => handleNavClick("list")}
            >
              List
            </NavLink>
            <NavLink
              index={1}
              active={selectedView === "map"}
              onClick={() => handleNavClick("map")}
            >
              Map
            </NavLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", null, "row"],
              height: "100%",
              position: "relative",
              alignItems: "center"
            }}
          >
            <FinderMap
              results={locationsWithProducts}
              setSelectedLocation={setSelectedLocation}
              selectedLocation={selectedLocation}
              hidden={selectedView === "list"}
              {...map}
            />
            <FinderListViewWrapper isMobileView={isMobileView}>
              {!isSearching && !locationsWithProducts.length && placeId && (
                <Box
                  sx={{
                    background: "background01",
                    borderRadius: [0, null, "large"],
                    p: 4,
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  <Text size="lg" variant={"bold"}>
                    No results found
                  </Text>
                </Box>
              )}

              <FinderList
                {...finderListProps}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                defaultOpen={isMobileMapView}
                searchTerm={sanitizedSearchTerm}
                // only show one location at a time on mobile map view, otherwise show all locations
                locations={
                  !isMobileMapView
                    ? locationsWithProducts
                    : selectedLocation
                      ? [selectedLocation]
                      : []
                }
              />
            </FinderListViewWrapper>
          </Box>
        </ThemeOverride>
      )}
    </>
  );
};
