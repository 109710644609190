import { Box, BrandLogo, IconButton, NavPanel, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { useAvailableLanguages } from "@/hooks/useAvailableLanguages";
import { LabelHeaderBlockProps, SplitButtonBlockProps } from "@/types/Block";
import { countryCodeMap } from "@/utils/country";
import { languageCodeMap } from "@/utils/language";

import { CountryModal } from "../blocks/CountryModal";
import { LanguageModal } from "../blocks/LanguageModal";
import { ButtonClickHandlers, SplitButton } from "./SplitButton";
import { getFlagIcon } from "./utils";

export const LabelsHeader: React.FC<LabelHeaderBlockProps> = ({
  labelDesktop,
  labelMobile,
  logo,
  countryModalBlockProps,
  languageModalBlockProps
}) => {
  const { country: countryCode, language } = useExperience();

  const [navPanelVisible, setNavPanelVisible] = React.useState(false);
  const [countryModalVisible, setCountryModalVisible] = React.useState(false);
  const [languageModalVisible, setLanguageModalVisible] = React.useState(false);
  const handleNavPanelDismiss = React.useCallback(() => setNavPanelVisible(false), []);
  const handleNavPanelOpen = React.useCallback(() => setNavPanelVisible(true), []);
  const handleCountryModalDismiss = React.useCallback(() => setCountryModalVisible(false), []);
  const handleCountryModalOpen = React.useCallback(() => setCountryModalVisible(true), []);
  const handleLanguageModalDismiss = React.useCallback(() => setLanguageModalVisible(false), []);
  const handleLanguageModalOpen = React.useCallback(() => setLanguageModalVisible(true), []);

  const handleStartButtonClick = () => {
    handleCountryModalOpen();
  };

  const handleEndButtonClick = () => {
    handleLanguageModalOpen();
  };

  const countryName = countryCode ? countryCodeMap[countryCode] : undefined;
  const startIcon = countryCode ? getFlagIcon(countryCode) : undefined;
  const currentLanguageName = language ? languageCodeMap[language] : "";

  const availableLanguages = useAvailableLanguages();

  const splitButtonProps: SplitButtonBlockProps & ButtonClickHandlers = {
    startText: [
      {
        text: countryName,
        weight: "medium"
      }
    ],
    ...(availableLanguages.size >= 1
      ? { endText: [{ text: currentLanguageName, weight: "medium" }] }
      : {}),
    onStartButtonClick: handleStartButtonClick,
    onEndButtonClick: handleEndButtonClick,
    ...(startIcon ? { startIcon } : {})
  };

  return (
    <>
      <ThemeOverride mode="dark">
        <Box
          sx={{
            backgroundColor: "background01",
            px: [5, 5, 8],
            py: 3,
            lineHeight: 3,
            fontSize: 1,
            width: "100%",
            minHeight: 11
          }}
        >
          {labelDesktop && labelMobile && (
            <>
              <Box sx={{ display: ["none", "none", "block"] }}>
                <Text size="sm">{labelDesktop}</Text>
              </Box>
              <Box
                sx={{
                  display: ["flex", "flex", "none"],
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size="sm">{labelMobile}</Text>
              </Box>
            </>
          )}
        </Box>
      </ThemeOverride>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: [5, 5, 8],
          paddingTop: 6,
          paddingBottom: [6, 6, 12],
          justifyContent: "space-between",
          width: "100%"
        }}
        role="navigation"
      >
        {logo && <BrandLogo logo={logo} sx={{ height: [7, 7, 10], width: "auto" }} />}
        <Box sx={{ display: ["none", "none", "flex"] }}>
          {countryName && currentLanguageName && <SplitButton {...splitButtonProps} />}
        </Box>
        <Box sx={{ display: ["flex", "flex", "none"] }}>
          <IconButton
            icon="list-view"
            variant="transparent"
            aria-label="menu-toggle"
            size="lg"
            onClick={handleNavPanelOpen}
          />
        </Box>
      </Box>
      <NavPanel visible={navPanelVisible} onDismiss={handleNavPanelDismiss}>
        <Box>{countryName && currentLanguageName && <SplitButton {...splitButtonProps} />}</Box>
      </NavPanel>
      {countryName && (
        <CountryModal
          {...countryModalBlockProps}
          isOpen={countryModalVisible}
          onDismiss={handleCountryModalDismiss}
        />
      )}
      {currentLanguageName && (
        <LanguageModal
          {...languageModalBlockProps}
          isOpen={languageModalVisible}
          onDismiss={handleLanguageModalDismiss}
        />
      )}
    </>
  );
};
