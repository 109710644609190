import { useRouter } from "next/router";
import React from "react";

export const useUrlState = () => {
  const router = useRouter();

  const setPlaceId = React.useCallback(
    (placeId: string | number) => {
      router.push(
        {
          query: {
            ...router.query,
            placeId
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [router]
  );

  const setSelectedProducts = React.useCallback(
    (products: string[]) => {
      router.push(
        {
          query: {
            ...router.query,
            products: products.join(",")
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [router]
  );

  const placeId = router.query.placeId as string;
  const products = router.query.products as string;
  const selectedProducts = products ? products.split(",") : [];

  return {
    placeId,
    selectedProducts,
    setPlaceId,
    setSelectedProducts
  };
};
