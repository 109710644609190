import { DropdownOption } from "@modernatx/ui-kit-react";
import React from "react";

import { FinderLocation } from "@/types/FinderLocation";

import { FinderHeaderProps } from "../FinderHeader";
import { useUrlState } from "./useUrlState";

export const useFetchProductLocations = ({
  selectOptions,
  setSearchResults,
  setSearchTerm,
  setSearchValue,
  searchType
}: {
  selectOptions?: FinderHeaderProps["selectOptions"];
  setSearchResults: React.Dispatch<React.SetStateAction<FinderLocation[]>>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchType: "branded" | "unbranded";
}) => {
  const initialLoad = React.useRef(true);
  const { placeId, setPlaceId } = useUrlState();
  const [selectedSearchValue, setSelectedSearchValue] = React.useState<DropdownOption>();
  const [isSearching, setIsSearching] = React.useState(false);

  const setSelectedSearchValueCallback = React.useCallback(
    (value: DropdownOption) => {
      setSelectedSearchValue(value);
      setPlaceId(value.value);
      setSearchValue(value.label);
    },
    [setPlaceId, setSearchValue, setSelectedSearchValue]
  );

  React.useEffect(() => {
    if (selectedSearchValue && placeId) {
      setSearchTerm(selectedSearchValue?.label);
    }
  }, [selectedSearchValue, setSearchTerm, placeId]);

  // On first render, if placeId exists, get the location details and set up the initial state
  // OR if placeId changes (like it does during browser navigation), get the location details again
  React.useEffect(() => {
    if (placeId && (initialLoad.current || placeId !== selectedSearchValue?.value)) {
      setIsSearching(true);
      fetch(`/api/location-details?placeId=${placeId}`)
        .then((response) => response.json())
        .then((data) => {
          setSelectedSearchValueCallback({
            label: data.formatted_address,
            value: placeId
          });
        })
        .catch(() => {});
    }
    initialLoad.current = false;
    // disable the exhaustive-deps lint rule because adding selectedSearchValue.value would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId, initialLoad, setSelectedSearchValueCallback]);

  React.useEffect(() => {
    if (selectedSearchValue && setSearchResults) {
      const products =
        searchType === "unbranded"
          ? "unbranded"
          : selectOptions?.map((option) => option.searchForProducts?.join(",") || "") || [];
      setIsSearching(true);
      fetch(
        `/api/product-locations?placeId=${selectedSearchValue.value}&products=${products}&type=${searchType}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data.locations);
        })
        .catch(() => {
          setSearchResults([]);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [searchType, selectOptions, selectedSearchValue, setSearchResults]);

  return { setSelectedSearchValue: setSelectedSearchValueCallback, isSearching };
};
