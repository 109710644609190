import { Box, Link, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { LabelsFooterBlockProps } from "@/types/Block";

export const LabelsFooter: React.FC<LabelsFooterBlockProps> = ({ content }) => {
  const year = React.useMemo(() => new Date().getFullYear(), []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: [10, 10, 10],
        px: [4, 10, 16],
        py: [10, 10, 10]
      }}
    >
      <Box
        sx={{
          alignItems: ["flex-start", "center", "center"],
          display: "flex",
          flexDirection: ["column", "row", "row"],
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row", "row"],
            gap: [4, 6, 10],
            lineHeight: 3
          }}
        >
          {content?.links?.map((link, i) => (
            <Link key={i} href={link.href} target={link.target}>
              {link.text.join("\n")}
            </Link>
          ))}
        </Box>
        <Text as="p" sx={{ whiteSpace: "pre", pt: [4, 0, 0] }}>
          © {year} {content?.documentId}
        </Text>
      </Box>
    </Box>
  );
};
