import { Box, BrandLogo, IconButton, NavPanel, Target } from "@modernatx/ui-kit-react";
import { usePathname } from "next/navigation";
import React from "react";

import { ProductsHeaderBlockProps } from "@/types/Block";

import { NavLink } from "../navigation/NavLink";

export const CovidHeader: React.FC<ProductsHeaderBlockProps> = ({ logo, href, target, links }) => {
  const pathname = usePathname();
  const id = `c${React.useId().replace(/[^A-Z0-9]/gi, "")}`;
  const [visible, setVisible] = React.useState(false);

  const handleDismiss = React.useCallback(() => setVisible(false), []);
  const handleOpen = React.useCallback(() => setVisible(true), []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          py: [5, 5, 5],
          justifyContent: "space-between",
          height: [20, 20, 20]
        }}
        title="Primary Navigation"
        role="navigation"
      >
        <Target
          href={href}
          target={target}
          sx={{
            flexShrink: 0,
            marginInlineEnd: 5,
            marginInlineStart: [5, 5, 8]
          }}
        >
          <BrandLogo logo={logo || "moderna-logo"} sx={{ height: 8, width: "auto" }} />
        </Target>
        <Box
          sx={{
            display: ["none", "none", "flex"],
            fontSize: 2,
            fontWeight: "bold",
            gap: 10,
            overflowY: "auto",
            scrollbarWidth: "none",
            // @ts-ignore
            "::-webkit-scrollbar": {
              display: "none"
            }
          }}
        >
          {links?.map((link, i) => (
            <Box
              key={i}
              sx={{
                flexShrink: 0,
                paddingInlineEnd: i === links?.length - 1 ? [5, 5, 8] : null
              }}
            >
              <NavLink
                active={pathname === link.href}
                data-scroll={id}
                index={i}
                href={link.href}
                fontWeight={"bold"}
              >
                {link.title}
              </NavLink>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: ["flex", "flex", "none"] }}>
          <IconButton
            icon="list-view"
            variant="transparent"
            aria-label="menu-toggle"
            size="lg"
            onClick={handleOpen}
          />
        </Box>
      </Box>
      <NavPanel visible={visible} onDismiss={handleDismiss}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {links?.map((link, i) => (
            <Target
              key={i}
              href={link.href}
              sx={{
                color: "text01",
                fontSize: 3,
                fontWeight: "bold",
                lineHeight: 3,
                py: 2,
                transition: "colorMedium",
                ":hover": {
                  color: "accent01"
                }
              }}
              onClick={handleDismiss}
            >
              {link.title}
            </Target>
          ))}
        </Box>
      </NavPanel>
    </>
  );
};
