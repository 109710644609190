import { Box, TargetProvider } from "@modernatx/ui-kit-react";
import React from "react";

import { useInitializeRouter } from "@/hooks/useInitializeRouter";
import useTargetProvider from "@/hooks/useTargetProvider";
import { BlockPageProps, BlockProps, NavigationProps } from "@/types/Block";

import { Block } from "../Block";
import NavGroup from "../navigation/NavGroup";
import NavGroupItem from "../navigation/NavGroupItem";
import ExitModal from "./ExitModal";

interface ContentProps {
  content: BlockPageProps;
}

/* eslint-disable complexity */
export const RenderContent: React.FC<ContentProps> = ({ content }) => {
  useInitializeRouter();
  const { handleClick, resolveTarget } = useTargetProvider();

  const blocks = React.useMemo(() => {
    const before: BlockProps[] = [];
    const after: { title: string; blocks: BlockProps[] }[] = [];
    let hasNavigation = false;
    let navigation: NavigationProps | undefined;

    content?.blocks.forEach((block) => {
      if (block.component === "Navigation") {
        hasNavigation = true;
        navigation = block.props;
      } else if (hasNavigation) {
        if (navigation?.links) {
          if (after.length === 0) {
            after.push({
              title: "",
              blocks: [block]
            });
          } else {
            after[after.length - 1]?.blocks?.push(block);
          }
        } else if (
          "titleNavigation" in block.props &&
          typeof block.props.titleNavigation === "string"
        ) {
          after.push({
            title: block.props.titleNavigation,
            blocks: [block]
          });
        } else {
          after[after.length - 1]?.blocks?.push(block);
        }
      } else {
        before.push(block);
      }
    });

    return { before, after, navigation };
  }, [content?.blocks]);

  const hasNavigation = !!blocks.after.length;

  return (
    <TargetProvider clickHandler={handleClick} targetResolver={resolveTarget}>
      <Box as={!hasNavigation ? "main" : "div"}>
        <Box as={hasNavigation ? "header" : "div"}>
          {blocks.before.map((props, i) => {
            return <Block key={i} {...props} />;
          })}
        </Box>
        {hasNavigation && (
          <Box as="main">
            <NavGroup {...blocks.navigation}>
              {blocks.after.map((blockGroup, i) => {
                return (
                  <NavGroupItem key={i} navButtonLabel={blockGroup.title}>
                    {blockGroup.blocks.map((block, j) => {
                      return <Block key={block.block?.key || j} {...block} />;
                    })}
                  </NavGroupItem>
                );
              })}
            </NavGroup>
          </Box>
        )}
      </Box>
      {content.exitModal && <ExitModal />}
    </TargetProvider>
  );
};
