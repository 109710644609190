import { BoxSx, Target } from "@modernatx/ui-kit-react";
import React from "react";

interface NavLinkProps {
  active?: boolean;
  children: string;
  href?: string;
  index: number;
  fontWeight?: BoxSx["fontWeight"];
  onClick?: React.MouseEventHandler;
}

export const NavLink: React.FC<NavLinkProps> = ({
  active,
  children,
  href,
  index,
  onClick,
  fontWeight = "medium",
  ...other
}) => {
  return (
    <Target
      {...other}
      sx={{
        color: active ? "accent01" : "inherit",
        display: "block",
        fontSize: 2,
        fontWeight: fontWeight,
        lineHeight: 2,
        py: 6,
        backgroundImage: active
          ? (theme) => `linear-gradient(to bottom, ${theme.colors?.accent01} 75%, transparent 75%)`
          : "transparent",
        backgroundPosition: "0 3.25em",
        backgroundRepeat: "repeat-x",
        backgroundSize: "1px 4px",
        whiteSpace: "nowrap",
        ":hover": {
          backgroundImage: (theme) =>
            `linear-gradient(to bottom, ${theme.colors?.accent01} 75%, transparent 75%)`
        }
      }}
      data-nav-link={index}
      href={href}
      onClick={onClick}
    >
      {children}
    </Target>
  );
};
