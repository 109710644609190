import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { VaccineFinderBannerBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import { VaccineFinder } from "../mresvia/VaccineFinder";

export const VaccineFinderBanner: React.FC<VaccineFinderBannerBlockProps> = ({
  text,
  vaccineFinder
}) => {
  return (
    <Grid>
      <GridColumn>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <BlockText text={text} />
        </Box>
        {vaccineFinder && (
          <Grid>
            <GridColumn size={[4, 6, 6]} push={[0, 1, 3]}>
              <VaccineFinder {...vaccineFinder} />
            </GridColumn>
          </Grid>
        )}
      </GridColumn>
    </Grid>
  );
};
