import { DropdownOption, SearchBar } from "@modernatx/ui-kit-react";
import React from "react";

export interface StateSelectProps {
  label: string;
  search?: (arg: string) => void;
}
interface StateOption extends DropdownOption {
  value: string;
}
const stateOptions: StateOption[] = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "District of Columbia", value: "DC" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];
const errorMessage = "Unable to find state. Please select one from the dropdown";

const filterDropdownOptions = (value: string) => {
  const cleanValue = value.trim().toLowerCase();
  return stateOptions.filter(
    (stateOption) =>
      stateOption.label.toLowerCase().startsWith(cleanValue) ||
      stateOption.value.trim().toLowerCase().startsWith(cleanValue)
  );
};

export const StateSelect: React.FC<StateSelectProps> = ({
  label,
  // TODO - Remove auto-assign when StateSelect is incorporated with graph/map - search will always be passed in
  // eslint-disable-next-line no-console
  search = () => console.log("searching")
}) => {
  const [value, setValue] = React.useState("");
  const [isError, setIsError] = React.useState<boolean>(false);
  const dropdownOptions = React.useMemo(() => filterDropdownOptions(value), [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleSubmit = React.useCallback(
    (state: string) => {
      const optionMatch = stateOptions.find(
        (stateOption) => state.toLowerCase() === stateOption.label.toLowerCase()
      );
      if (optionMatch) {
        search(optionMatch.label);
      } else {
        setIsError(true);
      }
    },
    [search]
  );

  const handleSelectOption = (option: DropdownOption) => {
    setIsError(false);
    setValue(option.label);
    handleSubmit(option.label);
  };

  return (
    <SearchBar
      data-test-id="searchBar"
      dropdownOptions={dropdownOptions}
      error={isError ? errorMessage : undefined}
      value={value}
      type="select"
      label={label}
      searchIcon={undefined}
      onChange={handleChange}
      onSelectOption={handleSelectOption}
    />
  );
};
