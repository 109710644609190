import Head from "next/head";
import React from "react";

import { config } from "@/config/environment";
import { Metadata } from "@/types/Block";

interface RenderMetadataProps extends Metadata {
  renderGoogleRecaptcha?: boolean;
}
export const RenderMetadata: React.FC<RenderMetadataProps> = ({
  title,
  metaTags,
  favicon,
  renderGoogleRecaptcha = false
}) => {
  return (
    <Head>
      <title>{title}</title>;
      {metaTags?.map((metaTag, i) => (
        <meta key={i} name={metaTag.name} property={metaTag.property} content={metaTag.content} />
      ))}
      {favicon && <link rel="icon" href={favicon} />}
      {renderGoogleRecaptcha && (
        <>
          <script
            async={true}
            src={`https://www.google.com/recaptcha/enterprise.js?render=${config.recaptchaSiteKey}`}
          />
          <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
        </>
      )}
    </Head>
  );
};
