import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useMarkerLibrary } from "./GoogleAPIProvider";
import { useMapInstance } from "./Map";

interface AdvancedMarkerEventProps {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
}

export type AdvancedMarkerProps = React.PropsWithChildren<
  Omit<
    google.maps.marker.AdvancedMarkerElementOptions,
    "gmpDraggable" | "gmpClickable" | "content" | "map" | "collisionBehavior"
  > &
    AdvancedMarkerEventProps
>;

export const AdvancedMarker = ({
  position,
  children,
  onClick,
  onDrag,
  onDragEnd,
  onDragStart,
  zIndex
}: AdvancedMarkerProps) => {
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const markerLibrary = useMarkerLibrary();
  const map = useMapInstance();

  // put these props in a ref to avoid inflating the deps array
  const positionRef = useRef(position);
  const zIndexRef = useRef(zIndex);

  if (!markerLibrary) {
    throw new Error("<AdvancedMarker/> must be wrapped in a <GoogleAPIProvider/>");
  }

  // Marker creation and initial setup
  useEffect(() => {
    if (!markerLibrary || !map) {
      return;
    }

    const markerElement = new markerLibrary.AdvancedMarkerElement({
      map,
      position: positionRef.current,
      zIndex: zIndexRef.current || null
    });

    // Create a content container for the marker so that it is rendered on first load
    const contentContainer = document.createElement("div");
    markerElement.content = contentContainer;
    contentContainerRef.current = contentContainer;

    setMarker(markerElement);

    return () => {
      markerElement.map = null;
      setMarker(null);
      contentContainerRef.current = null;
    };
  }, [markerLibrary, map]);

  // Update marker position and zIndex
  useEffect(() => {
    if (marker) {
      marker.position = position;
      marker.zIndex = zIndex || null;
    }
  }, [marker, position, zIndex]);

  // Add event listeners
  useEffect(() => {
    if (!marker) {
      return;
    }

    if (onClick) {
      marker.addListener("click", onClick);
    }
    if (onDrag) {
      marker.addListener("drag", onDrag);
    }
    if (onDragStart) {
      marker.addListener("dragstart", onDragStart);
    }
    if (onDragEnd) {
      marker.addListener("dragend", onDragEnd);
    }
    return () => {
      google.maps.event.clearInstanceListeners(marker);
    };
  }, [marker, onClick, onDrag, onDragStart, onDragEnd]);

  // Handle children (content) updates
  useEffect(() => {
    if (marker && children && !contentContainerRef.current) {
      const contentContainer = document.createElement("div");
      marker.content = contentContainer;
      contentContainerRef.current = contentContainer;
    }
  }, [children, marker, contentContainerRef]);

  if (!contentContainerRef.current) {
    return null;
  }

  return createPortal(children, contentContainerRef.current);
};

AdvancedMarker.displayName = "AdvancedMarker";
