import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

// FinderList renders the list of locations, taking into consideration the
// selected view and size breakpoints
export const FinderListViewWrapper = ({
  children,
  isMobileView
}: {
  children: React.ReactNode;
  isMobileView: boolean;
}) => {
  if (!isMobileView) {
    return (
      <Box
        sx={{
          width: "100%",
          pointerEvents: "none",
          height: "100%",
          position: "absolute"
        }}
      >
        <Grid sx={{ height: "100%" }}>
          <GridColumn size={[4, 8, 6]} sx={{ height: "100%", justifyContent: "start" }}>
            <Box
              sx={{
                // @ts-ignore
                maxHeight: "90%",
                width: "100%",
                boxShadow: "boxShadow02",
                my: 6,
                borderRadius: "large"
              }}
            >
              {children}
            </Box>
          </GridColumn>
          <GridColumn size={[4, 8, 6]}>
            {/* Second Column Content left intentionally empty */}
          </GridColumn>
        </Grid>
      </Box>
    );
  }
  return <>{children}</>;
};
