import { useEffect } from "react";

import { Expanded } from "@/components/blocks/ISIPanel";

const useBodyScrollLock = (isLocked: Expanded, panelRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (panelRef.current) {
        const panelHeight = panelRef.current.offsetHeight;
        const windowHeight = window.innerHeight;

        if (isLocked === true && panelHeight >= windowHeight) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.removeProperty("overflow");
        }
      }
    };

    handleScrollLock();
    window.addEventListener("resize", handleScrollLock);

    return () => {
      document.body.style.removeProperty("overflow");
      window.removeEventListener("resize", handleScrollLock);
    };
  }, [isLocked, panelRef]);
};

export default useBodyScrollLock;
