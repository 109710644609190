import React from "react";

interface TestPositivityData {
  week_end: string;
  pathogen: string;
  percent_test_positivity: number;
}

interface BarData {
  covid: number;
  flu: number;
  rsv: number;
}

const fetchEdVisitData = async (state?: string) => {
  try {
    const queryParam = state ? `?geography=${encodeURIComponent(state)}` : "";
    const response = await fetch(`/api/cdc-covid-ed-visits${queryParam}`);
    const data = await response.json();
    const edVisitBarData = data.no_data
      ? { covid: 0, flu: 0, rsv: 0 }
      : {
          covid: data[0].percent_visits_covid,
          flu: data[0].percent_visits_influenza,
          rsv: data[0].percent_visits_rsv
        };
    return edVisitBarData;
  } catch (_) {
    throw new Error("Error fetching Emergency Room Visit data");
  }
};

const fetchTestPositivityData = async () => {
  try {
    const response = await fetch("/api/cdc-respiratory-pathogens-positives");
    const { pathogens } = await response.json();
    const testPositivityData = {
      covid:
        pathogens.find((d: TestPositivityData) => d.pathogen === "COVID-19")
          ?.percent_test_positivity || 0,
      flu:
        pathogens.find((d: TestPositivityData) => d.pathogen === "Influenza")
          ?.percent_test_positivity || 0,
      rsv:
        pathogens.find((d: TestPositivityData) => d.pathogen === "RSV")?.percent_test_positivity ||
        0
    };
    return testPositivityData;
  } catch (_) {
    throw new Error("Error fetching Test Positivity data");
  }
};

const useBarChartData = (dataType: string, state?: string) => {
  const [barData, setBarData] = React.useState<BarData>({ covid: 0, flu: 0, rsv: 0 });
  const barDataCache = React.useRef<Record<string, BarData>>({});

  React.useEffect(() => {
    const key = `${dataType}-${state || "US"}`;
    if (barDataCache.current[key]) {
      if (!!barDataCache.current[key]) {
        setBarData(barDataCache.current[key] || { covid: 0, flu: 0, rsv: 0 });
      }
    } else {
      if (dataType === "test-positivity") {
        fetchTestPositivityData().then((data) => {
          barDataCache.current[key] = data;
          setBarData(data);
        });
      } else if (dataType === "ed-visits") {
        fetchEdVisitData(state).then((data) => {
          barDataCache.current[key] = data;
          setBarData(data);
        });
      }
    }
  }, [dataType, state, barDataCache]);

  return barData;
};

export default useBarChartData;
