import {
  Box,
  Button,
  Input,
  ModernaTheme,
  Notification,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { EmailSignupError, useEmailSignUp } from "@/hooks/useEmailSignup";
import { NewsletterSignupBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

const NewsletterSignup: React.FC<NewsletterSignupBlockProps> = ({
  buttonText,
  consentCommercial,
  country,
  // SPKVX-1816 todo: can remove this prop once we have more elegant solution for handling the de newsletter signup.
  deNewsletterSignup,
  disclaimer,
  errorText,
  errorEmailFormatText,
  labelText,
  mResviaProInfo,
  successText,
  isCorpTheme = false
}) => {
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const { error, setValue, submitEmail, value } = useEmailSignUp({
    apiEndpoint: "/api/newsletter-signup",
    consentCommercial,
    country,
    mResviaProInfo
  });
  const notificationText = React.useMemo(() => {
    if (error === EmailSignupError.InvalidEmail) {
      return errorEmailFormatText;
    } else if (error === EmailSignupError.ServerError) {
      return errorText;
    } else {
      return successText;
    }
  }, [errorEmailFormatText, errorText, error, successText]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
    [setValue]
  );

  const handleNotificationDismiss = React.useCallback(() => setNotificationOpen(false), []);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submitEmail().then(() => setNotificationOpen(true));
    },
    [submitEmail]
  );
  // SPKVX-1816 todo: remove this useMemo & references to the variable- we hopefully wont need to override theme when this component is completed
  const deColorOverrides = React.useMemo(() => {
    return deNewsletterSignup ? { colorsLight: { buttonBackgroundColorPrimary: "#E31837" } } : {};
  }, [deNewsletterSignup]);
  return (
    <>
      <ThemeOverride
        mode="light"
        theme={isCorpTheme ? { ...ModernaTheme, ...deColorOverrides } : undefined}
      >
        <Box
          as="form"
          sx={{
            alignItems: "stretch",
            display: "flex",
            flexDirection: ["column", "column", "row"],
            gap: 2
          }}
          onSubmit={handleSubmit}
        >
          <Input label={labelText} sx={{ flex: 1 }} value={value} onChange={handleChange} />
          <Button type="submit" sx={{ flexGrow: 0 }}>
            {buttonText}
          </Button>
        </Box>
        <Notification
          closeDelay={error === EmailSignupError.ServerError ? undefined : 3000}
          icon={error === null ? "check-circle" : "close-circle"}
          isOpen={notificationOpen}
          showX={error === EmailSignupError.ServerError}
          status={error === null ? "success" : "error"}
          text={notificationText}
          onDismiss={handleNotificationDismiss}
        />
      </ThemeOverride>
      <Box>{disclaimer && <BlockText {...disclaimer} />}</Box>
    </>
  );
};

export default NewsletterSignup;
