import Image from "next/image";
import React from "react";

import { config } from "@/config/environment";
import { FinderLocation } from "@/types/FinderLocation";

import { AdvancedMarker } from "./googleMaps/AdvancedMarker";
import { GoogleAPIProvider } from "./googleMaps/GoogleAPIProvider";
import { Map } from "./googleMaps/Map";
import MapViewController from "./MapViewController";

const FinderMarker = React.memo(({ active }: { active: boolean }) => {
  return (
    <Image
      alt="Custom Marker"
      src={`/assets/images/finder-icon${active ? "-active" : ""}.svg`}
      width={50}
      height={50}
    />
  );
});
FinderMarker.displayName = "FinderMarker";

export interface FinderMapBlockProps {
  hidden?: boolean;
  latitude: number;
  longitude: number;
  zoom: number;
  zoomControl?: boolean;
  results?: FinderLocation[];
  setSelectedLocation?: React.Dispatch<React.SetStateAction<FinderLocation | null>>;
  selectedLocation?: FinderLocation | null;
}

export const FinderMap: React.FC<FinderMapBlockProps> = React.memo(
  ({
    hidden,
    latitude,
    longitude,
    zoom,
    zoomControl = false,
    results = [],
    selectedLocation,
    setSelectedLocation = () => {}
  }) => {
    const isSelectedLocation = (location?: FinderLocation) => {
      return selectedLocation?.customer.id === location?.customer.id;
    };

    const handleMarkerClick = (searchResult: FinderLocation) => () =>
      setSelectedLocation(searchResult);

    return (
      <GoogleAPIProvider apiKey={config.googleMapsApiKey}>
        <Map
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={zoom}
          disableDefaultUI={true}
          hidden={hidden}
          zoomControl={zoomControl}
          // 8887f0dd602f7e7a is a mapID created by Kaio Barbosa-Chifan, linked to his personal account.
          // If changes are needed that depend on the google cloud map ID, contact kaio.barbosachifan@modernatx.com or
          // simply create and use a new map ID https://console.cloud.google.com/google/maps-apis/studio/maps
          mapId="8887f0dd602f7e7a"
        >
          <MapViewController results={results} selectedLocation={selectedLocation} />
          {results.map((searchResult) => (
            <AdvancedMarker
              key={searchResult.customer.id}
              position={{
                lat: searchResult.location.position.lat,
                lng: searchResult.location.position.lon
              }}
              zIndex={isSelectedLocation(searchResult) ? 1000 : null}
              onClick={handleMarkerClick(searchResult)}
            >
              <FinderMarker active={isSelectedLocation(searchResult)} />
            </AdvancedMarker>
          ))}
        </Map>
      </GoogleAPIProvider>
    );
  }
);
FinderMap.displayName = "FinderMap";
