import { debounce } from "lodash";
import React from "react";

interface SearchSuggestion {
  description: string;
  place_id: string;
}

const fetchAutocompleteSearch = debounce(
  async (input: string, callback: (data: SearchSuggestion[]) => void) => {
    return fetch(`/api/location-search-autocomplete?input=${input}`)
      .then((response) => response.json())
      .then((data: { predictions: SearchSuggestion[] }) => {
        callback(data.predictions);
      });
  },
  200
);

export const useAutocompleteSearch = (input: string = "") => {
  const [searchSuggestions, setSearchSuggestions] = React.useState<{
    [input: string]: SearchSuggestion[];
  }>({});

  const fetchCallback = React.useCallback(
    (data: SearchSuggestion[]) => {
      setSearchSuggestions((prev) => ({
        ...prev,
        [input]: data
      }));
    },
    [input]
  );

  React.useEffect(() => {
    if (input && input.length > 1) {
      fetchAutocompleteSearch(input, fetchCallback);
    } else {
      fetchCallback([]);
    }
  }, [input, fetchCallback]);

  return {
    isFetchingSuggestions: !searchSuggestions[input],
    searchSuggestions: searchSuggestions[input] || []
  };
};
