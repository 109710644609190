import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { Country } from "@/types/Country";

export const useAvailableCountries = () => {
  const { alternates = [], country, isProduction } = useExperience();

  const availableCountries = React.useMemo(() => {
    const newCountrySet = new Set<Country>();
    alternates.forEach((alt) => {
      if ((isProduction && alt.isLiveInProduction) || !isProduction) {
        if (alt.country && alt.country !== country) {
          newCountrySet.add(alt.country);
        }
      }
    });
    return newCountrySet;
  }, [alternates, country, isProduction]);

  return availableCountries;
};
