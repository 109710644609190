import {
  Box,
  BrandLogo,
  IconButton,
  NavPanel,
  Target,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { ProductsHeaderBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const ProductsHeader: React.FC<ProductsHeaderBlockProps> = ({
  logo,
  height,
  href,
  target,
  links,
  ribbonProps
}) => {
  const [visible, setVisible] = React.useState(false);

  const handleDismiss = React.useCallback(() => setVisible(false), []);
  const handleOpen = React.useCallback(() => setVisible(true), []);
  return (
    <>
      {ribbonProps && (
        <ThemeOverride mode="dark">
          <Box
            sx={{
              backgroundColor: "background01",
              px: [5, 5, 8],
              py: 3,
              lineHeight: 3,
              fontSize: 1,
              width: "100%"
            }}
          >
            <BlockText text={ribbonProps.labelDesktop} />
            <BlockText text={ribbonProps.labelMobile} />
          </Box>
        </ThemeOverride>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: [5, 5, 8],
          py: [2, 2, 5],
          justifyContent: "space-between"
        }}
        role="navigation"
      >
        {logo && (
          <Target href={href} target={target}>
            <BrandLogo logo={logo} sx={{ height: height || [7, 7, 10], width: "auto" }} />
          </Target>
        )}
        <Box
          sx={{
            display: ["none", "none", "flex"],
            fontSize: 2,
            fontWeight: "bold"
          }}
        >
          {links?.map((link, i) => (
            <Target
              key={i}
              sx={{
                display: "flex",
                p: 5,
                marginInlineEnd: i === links.length - 1 ? -5 : undefined,
                transition: "colorMedium",
                whiteSpace: "nowrap",
                ":hover": {
                  color: "accent01"
                }
              }}
              href={link.href}
            >
              {link.title}
            </Target>
          ))}
        </Box>
        {links && (
          <Box sx={{ display: ["flex", "flex", "none"] }}>
            <IconButton
              icon="list-view"
              variant="transparent"
              aria-label="menu-toggle"
              size="lg"
              onClick={handleOpen}
            />
          </Box>
        )}
      </Box>
      {links && (
        <ThemeOverride mode="light">
          <NavPanel visible={visible} onDismiss={handleDismiss}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              {links?.map((link, i) => (
                <Target
                  key={i}
                  href={link.href}
                  target={link.external ? "_blank" : undefined}
                  sx={{
                    color: "text01",
                    fontSize: 3,
                    fontWeight: "bold",
                    lineHeight: 3,
                    py: 2,
                    transition: "colorMedium",
                    ":hover": {
                      color: "accent01"
                    }
                  }}
                >
                  {link.title}
                </Target>
              ))}
            </Box>
          </NavPanel>
        </ThemeOverride>
      )}
    </>
  );
};
