import {
  Box,
  BrandLogo,
  IconButton,
  Link,
  NavPanel,
  Target,
  Text,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { MresviaHeaderBlockProps } from "@/types/Block";

export const MresviaHeader: React.FC<MresviaHeaderBlockProps> = ({
  labelMobile,
  labelDesktop,
  linkMobile,
  links,
  href,
  target,
  height,
  logo
}) => {
  const [visible, setVisible] = React.useState(false);

  const handleDismiss = React.useCallback(() => setVisible(false), []);
  const handleOpen = React.useCallback(() => setVisible(true), []);

  return (
    <>
      <ThemeOverride mode="dark">
        <Box
          sx={{
            backgroundColor: "background01",
            px: [5, 5, 8],
            py: 3,
            lineHeight: 3,
            fontSize: 1,
            width: "100%"
          }}
        >
          <Box sx={{ display: ["none", "none", "block"] }}>
            <Text size="sm">{labelDesktop}</Text>
          </Box>
          <Box
            sx={{
              display: ["flex", "flex", "none"],
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Text size="sm">{labelMobile}</Text>
            <Link
              sx={{ color: "text01" }}
              target={linkMobile.external ? "_blank" : undefined}
              href={linkMobile.href}
            >
              {linkMobile.title}
            </Link>
          </Box>
        </Box>
      </ThemeOverride>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: [5, 5, 8],
          py: [5, 5, 3],
          justifyContent: "space-between",
          height: [23, 23, 24]
        }}
        title="Primary Navigation"
        role="navigation"
      >
        <Target href={href} target={target}>
          <BrandLogo
            logo={logo || "mresvia-logo"}
            sx={{ height: height || [14, 14, 18], width: "auto" }}
          />
        </Target>
        <Box
          sx={{
            display: ["none", "none", "flex"],
            fontSize: 2,
            fontWeight: "bold"
          }}
        >
          {links?.map((link, i) => (
            <Target
              key={i}
              sx={{
                display: "flex",
                p: 5,
                marginInlineEnd: i === links.length - 1 ? -5 : undefined,
                transition: "colorMedium",
                whiteSpace: "nowrap",
                ":hover": {
                  color: "accent01"
                }
              }}
              href={link.href}
            >
              {link.title}
            </Target>
          ))}
        </Box>
        <Box sx={{ display: ["flex", "flex", "none"] }}>
          <IconButton
            icon="list-view"
            variant="transparent"
            aria-label="menu-toggle"
            size="lg"
            onClick={handleOpen}
          />
        </Box>
      </Box>
      <NavPanel visible={visible} onDismiss={handleDismiss}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {links?.slice(1).map((link, i) => (
            <Target
              key={i}
              href={link.href}
              target={link.external ? "_blank" : undefined}
              sx={{
                color: "text01",
                fontSize: 3,
                fontWeight: "bold",
                lineHeight: 3,
                py: 2,
                transition: "colorMedium",
                ":hover": {
                  color: "accent01"
                }
              }}
              onClick={handleDismiss}
            >
              {link.title}
            </Target>
          ))}
        </Box>
      </NavPanel>
    </>
  );
};
