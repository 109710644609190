import { useState } from "react";

const useRadioGroup = (initialValue: string) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return {
    selectedValue,
    handleChange
  };
};

export default useRadioGroup;
