import { useRouter } from "next/router";
import React from "react";

import { useExitModal } from "@/hooks/useExitModal";

const getMouseEventOpensInNewWindow = (event: React.MouseEvent) => {
  const target = event.currentTarget.getAttribute("target");

  return (
    (target && target !== "_self") ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey ||
    (event.nativeEvent && event.nativeEvent.which === 2)
  );
};

const useTargetProvider = () => {
  const { openExitModal, getIsExternalLink } = useExitModal();
  const router = useRouter();

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      const href = e.currentTarget.getAttribute("href");
      if (e.currentTarget instanceof HTMLAnchorElement) {
        if (e.currentTarget.hasAttribute("data-exiting-link")) {
          openExitModal(false);
        } else if (href && getIsExternalLink(href)) {
          e.preventDefault();
          openExitModal(true);
        } else if (href?.startsWith("/") && !getMouseEventOpensInNewWindow(e)) {
          e.preventDefault();
          const scroll = e.currentTarget.getAttribute("data-scroll");

          if (!scroll) {
            router.push(href);
          } else {
            router.push(href, undefined, { scroll: false });

            const element = document.getElementById(scroll);

            if (scroll !== "false" && element && element.getBoundingClientRect().top <= 0) {
              document.getElementById(scroll)?.scrollIntoView();
            }
          }
        }
      }
    },
    [getIsExternalLink, openExitModal, router]
  );

  const resolveTarget = React.useCallback((href?: string) => {
    if (href?.startsWith("/")) {
      return "_self";
    }
    return "_blank";
  }, []);

  return { handleClick, resolveTarget };
};

export default useTargetProvider;
