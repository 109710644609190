export const isAllUpperCase = (string: string): boolean => {
  return string === string.toUpperCase();
};

export const stringToTitleCase = (string: string): string => {
  return string.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

export const titleCaseWithExclusions = (name: string, exclusions: Set<string>): string => {
  const words = name.split(" ");
  const formattedWords = words.map((word) => {
    if (exclusions.has(word.toUpperCase())) {
      return word.toUpperCase();
    }
    if (isAllUpperCase(word)) {
      return stringToTitleCase(word);
    }
    return word;
  });
  return formattedWords.join(" ");
};

export const removeSpecialCharacters = (address: string): string => {
  return address.replace(/[^\w\s#]/gi, "");
};

// Set of pharmacy names to exclude from title casing
export const pharmacyNamesToExclude = new Set(["CVS", "QFC"]);

export function getGoogleMapsLink(
  storeName: string,
  streetAddress: string,
  city: string,
  state: string,
  postalCode: string
): string {
  const query = encodeURIComponent(
    `${storeName}, ${streetAddress}, ${city}, ${state} ${postalCode}`
  );
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
}

export const getMapStyles = () => {
  const stylersAdministrative = [{ color: "#3cabe0" }];
  const stylersLandscape = [{ color: "#ffffff" }];
  const stylersManMade = [{ color: "#f2fafd" }];
  const stylersRoad = [{ color: "#addcf2" }];
  const stylersTextFill = [{ color: "#01283c" }];
  const stylersTextStroke = [{ color: "#ffffff" }];
  const stylersWater = [{ color: "#3cabe0" }];

  return [
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: stylersTextFill },
    { elementType: "labels.text.stroke", stylers: stylersTextStroke },
    { elementType: "geometry", featureType: "landscape.natural", stylers: stylersLandscape },
    { elementType: "geometry", featureType: "poi", stylers: stylersManMade },
    { elementType: "geometry", featureType: "poi.park", stylers: stylersLandscape },
    { elementType: "geometry", featureType: "landscape.man_made", stylers: stylersManMade },
    { elementType: "geometry", featureType: "road", stylers: stylersRoad },
    { elementType: "geometry.stroke", featureType: "road.highway", stylers: stylersTextStroke },
    {
      elementType: "geometry.stroke",
      featureType: "administrative.country",
      stylers: stylersAdministrative
    },
    {
      elementType: "geometry.stroke",
      featureType: "administrative.province",
      stylers: stylersAdministrative
    },
    { elementType: "geometry", featureType: "transit.station", stylers: stylersManMade },
    { elementType: "geometry", featureType: "transit.line", stylers: stylersRoad },
    { elementType: "geometry", featureType: "water", stylers: stylersWater }
  ];
};
