import { Language } from "@/types/Language";

export const languageCodeMap: Partial<Record<Language, string>> = {
  de: "Deutsch",
  nl: "Dutch",
  fr: "French",
  bg: "Bulgarian",
  hr: "Croatian",
  el: "Greek",
  cs: "Czech",
  da: "Danish",
  et: "Estonian",
  fi: "Finnish",
  hu: "Hungarian",
  en: "English",
  it: "Italian",
  lv: "Latvian",
  lt: "Lithuanian",
  mt: "Maltese",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  sk: "Slovak",
  sl: "Slovene",
  es: "Spanish",
  sv: "Swedish"
};
